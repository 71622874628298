import { useIsMobileContext } from '../../context/context';
import './RoadMap.css';

export type RoadMapCardProps = {
    image: string;
    title: string;
    description: string;
    index: number;
}
export const RoadMapCard = ({image, title, description, index}: RoadMapCardProps) => {
    const isMobile = useIsMobileContext()
    return (
        <div className={isMobile ? "CardWrapperMobile" : "CardWrapper"}>
            {
                index % 2 === 0 && 
                <div className='CardImageWrapper'>
                    <img src={image} alt='home-logo' height={isMobile ? 280 : 450}/>
                    <div className='CardImageBackground'/>
                </div>
            }
            
            <div className={isMobile ? "CardTextWrapperMobile" : "CardTextWrapper"}>
                <p className='CardTitle'>{title}</p>
                <p className='CardSubTitle'>{description}</p>
            </div>

            {
                index % 2 !== 0 && 
                <div className='CardImageWrapper'>
                    <img src={image} alt='home-logo' height={isMobile ? 280 : 450}/>
                    <div className='CardImageBackground'/>
                </div>
            }
        </div>
    )
}