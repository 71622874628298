import { RoadMapCard } from "./RoadMapCard"
import homeScreen from '../../../assets/icons/home-screen.png'
import mapScreen from '../../../assets/icons/map-screen.png'
import chatScreen from '../../../assets/icons/chat-screen.png'
import detailScreen from '../../../assets/icons/detail-screen.png'

export const RoadMapList = () => {
    return (
        <div className="ListWrapper">
            <p className="RoadMapTitle">How Gluten Freends is Unique</p>
            <RoadMapCard 
                image={homeScreen} 
                index={0} 
                title="Socializing and travel planning" 
                description="Create or join Gluten Free events at restaurants, bars, homes, or travel spots, and connect with like-minded people. From intimate dinner parties to adventurous group trips, Gluten Freends makes it easy to find Gluten Free gatherings and build a supportive community."
            />
            <RoadMapCard 
                image={mapScreen} 
                index={1} 
                title="Discover events in your area" 
                description="Discover and join Gluten Free events happening near you, whether at restaurants, bars, homes, or unique travel spots."
            />
            <RoadMapCard 
                image={chatScreen} 
                index={2} 
                title="Connect with others" 
                description="Connect with others who share your lifestyle, join events and speak with the participants before to meet them by person."
            />
            <RoadMapCard 
                image={detailScreen} 
                index={3} 
                title="More then a meet up application" 
                description="After you join an event, you can upload photos, share your experiences, and rate the event creator. Whether it's a Gluten Free dinner party, a night out with friends, or a group trip, your feedback helps others discover the best Gluten Free gatherings. Connect with the community, share your favorite moments, and support event creators by leaving ratings and reviews."
            />
        </div>
    )
}