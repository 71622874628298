import './App.css';
import { HeaderSection } from './components/sections/header/Header';
import { BadgeSection } from './components/sections/badge/Badge';
import { RoadMapList } from './components/sections/road-map/RoadMapList';
import { FooterSection } from './components/sections/footer/Footer';
import { useWindowsDimension } from './components/hooks/useWindowsDimension';
import { IsMobileContext } from './components/context/context';

function App() {
  const {isMobile} = useWindowsDimension()

  return (
    <div className="App">
      <IsMobileContext.Provider value={isMobile}>
        <HeaderSection />
        <BadgeSection showTitle={false}/>
        <RoadMapList />
        <BadgeSection />
        <FooterSection />
      </IsMobileContext.Provider>
    </div>
  );
}

export default App;
