import './Footer.css'
import {useState} from 'react';
import { ContactUs } from './contact-us/ContactUs';

export const FooterSection = () => {
    const GDPR_POLICY_URL = 'https://www.privacypolicies.com/live/180fe99b-b335-4150-b681-d91e7163b3d7';
    const [visible, setVisible] = useState(false)

    const handleContactClick = () => {
        setVisible(true)
    }

    return (
        <div className="FooterWrapper">
            <a className='FooterHref' href={GDPR_POLICY_URL}>
                <p className="FooterLabel">Privacy</p>
            </a>
            <p className="FooterLabel">|</p>
            <p className="FooterLabel" onClick={handleContactClick}>Contact</p>
            
            <ContactUs visible={visible} setVisible={setVisible}/>
        </div>
    )
}