import {ReactNode} from 'react';
import './Modal.css';

export type  ModalProps = {
    children: ReactNode;
    visible: boolean;
    modalTitle: string;
    primaryText: string;
    onClickPrimary: () => void;
    onClickSecondary: () => void;
}

export const Modal = ({children, visible, modalTitle, primaryText, onClickPrimary, onClickSecondary}: ModalProps) => {
    return (
        <div className='ModalContainer'>
            <dialog className='Modal' open={visible}>
                <div className='ModalHeaderWrapper'>
                    <p className='ModalTitle'>{modalTitle}</p>
                </div>
                {children}
                <div className="FooterModalWrapper">
                    <button type='button' className='SecondaryButton' onClick={onClickSecondary}>Close</button>
                    <button type='button' className='PrimaryButton' onClick={onClickPrimary}>{primaryText}</button>
                </div>
            </dialog>
        </div>
    )
}