import {useState} from 'react';
import { Modal } from '../../../modal/Modal';
import { createPortal } from 'react-dom';
import './ContactUs.css';
import emailjs from '@emailjs/browser';
import { toast, ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";

export type ContactUsProps = {
    visible: boolean;
    setVisible: React.Dispatch<React.SetStateAction<boolean>>
}

export const ContactUs = ({visible, setVisible}: ContactUsProps) => {
    const [email, setEmail] = useState('')
    const [message, setMessage] = useState('')

    const handleModalClose = () => {
        setVisible(false)
    }

    const validEmail = () => {
        var filter = /^\s*[\w\-+_]+(\.[\w\-+_]+)*@[\w\-+_]+\.[\w\-+_]+(\.[\w\-+_]+)*\s*$/;
        return String(email).search (filter) !== -1;
    }

    const handleSend = () => {
        if(!email || !validEmail()) {
            toast.error("Email not valid!");
            return
        }
        if(!message) {
            toast.error("Please, insert a valid message!");
            return
        }

        emailjs
        .send('service_5rcbh52', 'template_prygeyr', {
            email,
            message,
            from_name: email
        }, {
            publicKey: 'yYS0j8kuu-0KYHX4j',
        })
        .then(() => {
                toast.success('Thank you for contacting us!')
                setVisible(false)
            },
            (error) => {
                toast.error("There is an error with the Email service, try again later or send an email directly to glutenfreends@gmail.com");
            },
        );
    }

    return (
        <>
        {visible && createPortal(
				<Modal 
                    visible={visible} 
                    onClickPrimary={handleSend}
                    onClickSecondary={handleModalClose}
                    modalTitle='Contact Us'
                    primaryText='Send'>
                    <div className="ContactUsWrapper">
                        <div className="ContactUsLabelWrapper">
                            <p className="ContactUsLabel">Email</p>
                            <input 
                                className='ContactUsEmailInput'
                                placeholder='Your email' 
                                type="email" 
                                value={email} 
                                onChange={(event) => setEmail(event.target.value)}
                            />
                        </div>
                        <div className="ContactUsLabelWrapper">
                            <p className="ContactUsLabel">Message</p>
                            <textarea 
                                className='ContactUsMessageInput'
                                placeholder='Your Message' 
                                value={message} 
                                onChange={(event) => setMessage(event.target.value)}
                            />
                        </div>
                    </div>
                </Modal>,
				document.body
			)}
            {
                createPortal(
                    <ToastContainer />,
                    document.body
                )
            }
        </>
        
    )
}