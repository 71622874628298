import {useEffect, useState} from 'react';

export const useWindowsDimension = () => {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 880)

    useEffect(() => {
        const handleResize = () => {
            const currentIsMobile = window.innerWidth <= 880
            setIsMobile(currentIsMobile)
        }
        
        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [])

    return {
        isMobile
    }
}