import './Badge.css'
import googleBadgeImage from '../../../assets/icons/google-play-badge.png'
import appleBadgeImage from '../../../assets/icons/apple-store-badge.png'
import { ToastContainer } from 'react-toastify'
import "react-toastify/dist/ReactToastify.css";
import { useIsMobileContext } from '../../context/context';

export const BadgeSection = ({showTitle = true} : {showTitle?: boolean}) => {
    const APPLE_STORE_URL = 'https://apps.apple.com/us/app/gluten-freends/id6479203808'
    const GOOGLE_STORE_URL = 'https://play.google.com/store/apps/details?id=com.glutenfreehub'

    const isMobile = useIsMobileContext()
    return (
        <div className='BadgePageWrapper'>
            {showTitle && <p className="BadgeTitle">Get Gluten Freends Now</p>}
            <div className='BadgeWrapper'>
                <div className='BadgeLeft'>
                    <a href={APPLE_STORE_URL}>
                        <img src={appleBadgeImage} alt='apple-logo' height={isMobile ? 45 : 80} />
                    </a>
                </div>
                <div className='BadgeRight'>
                    <a href={GOOGLE_STORE_URL}>
                        <img src={googleBadgeImage} alt='google-logo' height={isMobile ? 45 : 80} />
                    </a>
                </div>
                <ToastContainer />
            </div>
        </div>
    )
}