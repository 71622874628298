import './Header.css';
import headerImageV1 from '../../../assets/icons/header-images-v1.png'
import headerImage from '../../../assets/icons/header-images.png'
import { useIsMobileContext } from '../../context/context';

export const HeaderSection = () => {
    const isMobile = useIsMobileContext()

    return (
        <div className="HeaderWrapper">
            <div className='HeaderBackground'/>
            <div className='TitleWrapper'>
                <p className='Title'>Join Gluten Freends Community</p>
                <p className='SubTitle'>Connect with other Celiacs in your area. Explore our community and organize events.</p>
            </div>
            <div className='ImageContainer'>
                {   isMobile ?
                        <img src={headerImage} alt='home-logo' height={280}/>
                    :
                        <img src={headerImageV1} alt='home-logo' height={600}/>
                }
            </div>
        </div>
    )
}